import axios from 'axios';

export function getBookingConfirmationRequest(token, callback, errorcallback) {

    axios
        .get(`bookings/get_booking_confirmation_req?token=${token}`)
        .then((response) => {
          if (response.status == 200) {
              var booking = response.data.booking;
              callback(booking);
          }
        })
        .catch((err) => {
          errorcallback(err);
        });
}

import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Collapse, Container, Row } from 'react-bootstrap';
import {
   GoogleReCaptchaProvider,
   useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getAccountDataByToken } from '../actions/account';
import { verifyEmail } from '../actions/auth';
import { getPayrexxTransactionLink } from '../actions/paymntMethods';
import { completeRegistration, register } from '../actions/register';
import DropDownIcon from '../assets/icons/dropdown.png';
import FormCheckbox from '../components/FormCheckbox';
import FormInput from '../components/FormInput';
import StatusAlerts from '../components/StatusAlerts';
import { COMMUNICATION_LANGUAGES } from '../constants/constants';
import i18n from '../i18n';
import { validateRegisterform } from '../utils/validators';

const i18nPrefix = 'Register';

const dropDownArrowStyle = {
   transition: 'transform 250ms ease', // smooth transition
   float: 'right',
   height: 15,
   marginTop: 8,
   marginRight: 10,
};

function getNegativeBalance(account) {
   const balances = [
      { currency: 'CHF', amount: account.balance_CHF },
      { currency: 'EUR', amount: account.balance_EUR },
      { currency: 'USD', amount: account.balance_USD },
      { currency: 'GBP', amount: account.balance_GBP },
      { currency: 'DKK', amount: account.balance_DKK },
   ];

   let result = false;
   balances.forEach((balance) => {
      if (balance.amount < 0) {
         console.log('negativeBalance', balance);
         result = balance;
      }
   });
   return result;
}

const Register = (props) => {
   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState(false);
   const [accountExists, setAccountExists] = useState(false);
   const [dataFromUri, setDataFromUri] = useState(false);
   const [role, setRole] = useState('Private');

   // const [communication_language, setCommunication_language] = useState('en');
   // const [firstname, setFirstname] = useState('Florian');
   // const [lastname, setLastname] = useState('Bolli');
   // const [email, setEmail] = useState('florian.bol4li@g5mx.ch');
   // const [mobilenumber, setMobilenumber] = useState('+4178906767654');
   // const [password, setPassword] = useState('florian1');
   // const [passwordRepeat, setPasswordRepeat] = useState('florian1');

   const [communication_language, setCommunication_language] = useState();
   const [firstname, setFirstname] = useState();
   const [lastname, setLastname] = useState();
   const [email, setEmail] = useState();
   const [mobilenumber, setMobilenumber] = useState();
   const [password, setPassword] = useState();
   const [passwordRepeat, setPasswordRepeat] = useState();

   const [captchaToken, setCaptchaToken] = useState('');
   const [referralAccount, setReferralAccount] = useState('');
   const [acceptedTCs, setAcceptedTCs] = useState(false);
   const [club, setClub] = useState('');
   const [marina, setMarina] = useState('');
   const [accountToken, setAccountToken] = useState(false);
   const [mooringTransferToken, setMooringTransferToken] = useState(false);
   const [continueAsGuest, setContinueAsGuest] = useState(false);
   const [unregisteredAccount, setUnregisteredAccount] = useState(null);
   const [negativeBalance, setNegativeBalance] = useState(false);
   const [account, setAccount] = useState({});
   const [boat, setBoat] = useState({
      name: '',
      registration_number: '',
      length: null,
      beam: null,
      draft: null,
   });
   const [showBoatInfo, setShowBoatInfo] = useState(false);

   const { executeRecaptcha } = useGoogleReCaptcha();

   useEffect(() => {
      // Handle URL-based data prefilling
      if (props.match.params.args) {
         const args = props.match.params.args.split('&');
         const language = args[5].toLowerCase();

         setDataFromUri(true);
         setFirstname(args[0]);
         setLastname(args[1]);
         setEmail(args[2]);
         setMobilenumber(args[3]);
         setRole(args[4]);
         setCommunication_language(args[5].toUpperCase());
         setReferralAccount(args[6]);
         setClub(args[7]);
         setMarina(args[8]);
         setAccountToken(args[9]);
         i18n.changeLanguage(language);
      }

      // Handle mooring_transfer_token
      if (props.match.params.mooring_transfer_token) {
         setMooringTransferToken(props.match.params.mooring_transfer_token);
         setEmail(props.match.params.email);
      }

      // Handle account_token
      if (props.match.params.account_token) {
         const token = props.match.params.account_token;
         getAccountDataByToken(token, (account) => {
            setUnregisteredAccount(account);
            setAccount(account);
            setFirstname(account.firstname);
            setLastname(account.lastname);
            setEmail(account.email);
            setMobilenumber(account.mobilenumber);
            setCommunication_language(account.communication_language);
            setContinueAsGuest(true);
            setBoat(account.boat);
            setRole(account.role);
            setShowBoatInfo(true);
            setNegativeBalance(getNegativeBalance(account));

            if (props.match.params.email_activation_code) {
               const data = {
                  code: props.match.params.email_activation_code,
                  account_id: account.account,
               };
               verifyEmail(data, (response) => {
                  console.log('Response', response);
               });
            }
         });
      }
   }, [props.match.params]);

   const handleRegisterClick = async () => {
      if (executeRecaptcha) {
         const token = await executeRecaptcha('register');
         setCaptchaToken(token);

         const userData = {
            firstname,
            lastname,
            email,
            mobilenumber,
            password,
            passwordRepeat,
            captchaToken: token,
            communication_language,
            role,
            referral_account: referralAccount,
            club,
            marina,
            AcceptedTCs: acceptedTCs,
            boat,
            continue_as_guest: continueAsGuest,
         };

         const formErrors = validateRegisterform(userData);
         const noErrors = Object.values(formErrors).every((x) => x === false);

         if (!noErrors) {
            if (formErrors.AcceptedTCs !== false) {
               setError(props.t('errors.pleaseAcceptTCs'));
               return;
            }
            setError(props.t('errors.someValuesAreNotCorrect'));
         } else {
            setLoading(true);
            setSuccess(false);
            setError(false);

            if (accountToken) {
               completeRegistration(
                  { ...userData, account_id: account.account_id },
                  () => {
                     window.location = 'https://boatpark.app/';
                  },
                  registerFail,
               );
            } else {
               register(userData, boat, registerSuccess, registerFail);
            }
         }
      }
   };

   const handlePayClick = () => {
      if (unregisteredAccount.role === 'guest') {
         completeRegistration(
            { ...unregisteredAccount, boat, captchaToken: captchaToken },
            () => {
               getPayrexxTransactionLink(
                  -negativeBalance.amount,
                  negativeBalance.currency,
                  unregisteredAccount.account_id,
                  (link) => {
                     window.location.replace(link);
                  },
               );
            },
         );
      }
   };

   const registerSuccess = () => {
      toast(props.t(`${i18nPrefix}.registrationCompleted`), {
         type: 'success',
         autoClose: 5000,
      });
      setLoading(false);
      setError(false);
      setSuccess(true);
      props.history.push('/login');
   };

   const registerFail = (error) => {
      setError(props.t(`errors.${error.response.data.language_variable}`));
      setLoading(false);
      setSuccess(false);
   };

   const formErrors = validateRegisterform({
      firstname,
      lastname,
      email,
      mobilenumber,
      password,
      passwordRepeat,
      role,
      referral_account: referralAccount,
      AcceptedTCs: acceptedTCs,
      communication_language: communication_language,
   });

   const languages = COMMUNICATION_LANGUAGES;

   const registrationAfterFastBooking = Boolean(accountToken);

   const showGuestCheckbox =
      registrationAfterFastBooking && !account.email_already_exists;

   return (
      <Container
         style={{
            maxWidth: 800,
            marginTop: 50,
            borderRadius: 5,
            backgroundColor: 'white',
         }}>
         <h1>{props.t(`${i18nPrefix}.title`)}</h1>
         {accountExists ? (
            <Row>
               <Col>
                  <Alert key={1} variant={'primary'}>
                     {props.t('errors.thisEmailIsAlreadyTaken')}
                  </Alert>
                  <Button
                     onClick={() =>
                        (window.location.href = props.t('links.website'))
                     }>
                     {props.t('global.contunie')}
                  </Button>
               </Col>
            </Row>
         ) : (
            <Row>
               <Col>
                  <FormInput
                     as='select'
                     label={props.t(`${i18nPrefix}.role`)}
                     id='input_role'
                     value={role}
                     onChange={(event) => setRole(event.target.value)}
                     disabled={registrationAfterFastBooking}>
                     <option value='private'>Private</option>
                     <option value='marina'>Marina</option>
                     {registrationAfterFastBooking && (
                        <option value='guest'>Guest</option>
                     )}
                  </FormInput>

                  {showGuestCheckbox && (
                     <FormCheckbox
                        id='checkbox_continue_as_guest'
                        label={props.t(`${i18nPrefix}.continueAsGuest`)}
                        checked={continueAsGuest}
                        onChange={(e) => {
                           setContinueAsGuest(e.target.checked);
                           setRole(e.target.checked ? 'guest' : 'private');
                        }}
                     />
                  )}

                  <FormInput
                     type='text'
                     label={props.t(`${i18nPrefix}.firstName`)}
                     id='input_firstname'
                     value={firstname}
                     error={formErrors.firstname}
                     onChange={(event) => setFirstname(event.target.value)}
                  />
                  <FormInput
                     type='text'
                     label={props.t(`${i18nPrefix}.lastName`)}
                     id='input_lastname'
                     value={lastname}
                     error={formErrors.lastname}
                     onChange={(event) => setLastname(event.target.value)}
                  />
                  <FormInput
                     as='select'
                     label={props.t(`${i18nPrefix}.communicationLanguage`)}
                     id='input_communication_language'
                     value={communication_language?.toUpperCase()}
                     error={formErrors.communication_language}
                     onChange={(event) =>
                        setCommunication_language(event.target.value)
                     }>
                     <option value={null}></option>
                     {languages.map((language, index) => (
                        <option key={index} value={language}>
                           {language}
                        </option>
                     ))}
                  </FormInput>
                  <FormInput
                     type='text'
                     label={props.t(`${i18nPrefix}.email`)}
                     id='input_email'
                     value={email}
                     error={formErrors.email}
                     onChange={(event) => setEmail(event.target.value)}
                  />
                  <FormInput
                     type='tel'
                     label={props.t(`${i18nPrefix}.mobile`)}
                     id='input_mobilenumber'
                     value={mobilenumber}
                     error={formErrors.mobilenumber}
                     onChange={(event) => setMobilenumber(event.target.value)}
                     disabled={registrationAfterFastBooking}
                  />
                  {!continueAsGuest && (
                     <>
                        <FormInput
                           type='password'
                           label={props.t(`${i18nPrefix}.password`)}
                           id='input_password'
                           value={password}
                           error={formErrors.password}
                           onChange={(event) => setPassword(event.target.value)}
                        />
                        <FormInput
                           type='password'
                           label={props.t(`${i18nPrefix}.repeatPassword`)}
                           id='input_passwordRepeat'
                           value={passwordRepeat}
                           error={formErrors.passwordRepeat}
                           onChange={(event) =>
                              setPasswordRepeat(event.target.value)
                           }
                        />
                     </>
                  )}
                  {!accountToken && (
                     <FormInput
                        type='text'
                        name='referral_account'
                        label={props.t(`${i18nPrefix}.referral`)}
                        id='input_referral_account'
                        value={referralAccount}
                        error={formErrors.referral_account}
                        onChange={(event) =>
                           setReferralAccount(event.target.value)
                        }
                     />
                  )}

                  {/* Optional Boat Info */}
                  <Row onClick={() => setShowBoatInfo(!showBoatInfo)}>
                     <Col md={10} xs={10}>
                        <h3>{props.t(`${i18nPrefix}.OptionalBoatInfo`)}</h3>
                     </Col>
                     <Col md={2} xs={2}>
                        <img
                           style={{
                              ...dropDownArrowStyle,
                              transform: showBoatInfo ? 'rotate(180deg)' : '',
                           }}
                           src={DropDownIcon}
                           alt='DropDownIcon'
                           className='spin'
                        />
                     </Col>
                  </Row>
                  <Collapse in={showBoatInfo}>
                     <div id='showBoatInfo'>
                        <FormInput
                           id='input_name'
                           type='text'
                           label={props.t(`${i18nPrefix}.name`)}
                           value={boat?.name}
                           error={formErrors.name}
                           onChange={(event) =>
                              setBoat({ ...boat, name: event.target.value })
                           }
                        />
                        <FormInput
                           id='input_registration_number'
                           type='text'
                           label={props.t(`${i18nPrefix}.registration_number`)}
                           value={boat?.registration_number}
                           error={formErrors.registration_number}
                           onChange={(event) =>
                              setBoat({
                                 ...boat,
                                 registration_number: event.target.value,
                              })
                           }
                        />
                        <FormInput
                           id='input_length'
                           type='number'
                           label={props.t(`${i18nPrefix}.length`)}
                           unit='m'
                           value={boat?.length}
                           error={formErrors.length}
                           onChange={(event) =>
                              setBoat({ ...boat, length: event.target.value })
                           }
                           disabled={account?.boat?.length}
                        />
                        <FormInput
                           id='input_beam'
                           type='number'
                           label={props.t(`${i18nPrefix}.beam`)}
                           unit='m'
                           value={boat?.beam}
                           error={formErrors.beam}
                           onChange={(event) =>
                              setBoat({ ...boat, beam: event.target.value })
                           }
                           disabled={account?.boat?.beam}
                        />
                        <FormInput
                           id='input_draft'
                           type='number'
                           label={props.t(`${i18nPrefix}.draft`)}
                           unit='m'
                           value={boat?.draft}
                           error={formErrors.draft}
                           onChange={(event) =>
                              setBoat({ ...boat, draft: event.target.value })
                           }
                        />
                     </div>
                  </Collapse>

                  <FormCheckbox
                     id='checkbox_tcs'
                     type='checkbox'
                     label={
                        <span>
                           {props.t(`${i18nPrefix}.iAcceptThe`)}
                           <a
                              target='_blank'
                              rel='noreferrer'
                              href={props.t('links.TCsLink')}>
                              {props.t(`${i18nPrefix}.TCs`)}
                           </a>
                        </span>
                     }
                     onChange={(event) => setAcceptedTCs(event.target.checked)}
                     error={formErrors.AcceptedTCs}
                  />

                  {!continueAsGuest && (
                     <Button
                        disabled={loading}
                        id='button_register'
                        variant='primary'
                        onClick={handleRegisterClick}>
                        {props.t(`${i18nPrefix}.register`)}
                     </Button>
                  )}
                  {continueAsGuest && (
                     <Button
                        id='button_pay'
                        disabled={loading}
                        variant='primary'
                        onClick={handlePayClick}>
                        {props.t(`${i18nPrefix}.pay`)}
                     </Button>
                  )}

                  <StatusAlerts
                     errorMessage={error}
                     successMessage={props.t(`${i18nPrefix}.registerSuccess`)}
                     loadingMessage={props.t('global.loading')}
                     state={{ loading, success, error }}
                  />
               </Col>
            </Row>
         )}
      </Container>
   );
};

// Wrap Register component with GoogleReCaptchaProvider
const RegisterWithReCaptcha = (props) => {
   return (
      <GoogleReCaptchaProvider
         reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
         <Register {...props} />
      </GoogleReCaptchaProvider>
   );
};

export default withTranslation()(RegisterWithReCaptcha);

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const errorsExist = (formErrors) => {
   const noErrors = Object.values(formErrors).every((x) => x == false);
   return !noErrors;
};

/**
 * Checks if text not empty or undefined
 */
export const required = (text) => {
   return text == '' || text == null ? '*' : false;
};

export const noNegativeOne = (value) => {
   return value == -1 ? '*' : false;
};

export const notEqualToVariable = (value, variable) => {
   return value == variable ? '*' : false;
};

export const one_required = (value1, value2) => {
   if ((value1 == '' || value1 == null) && (value2 == '' || value2 == null)) {
      return '*';
   }
   return false;
};

/**Makes an input optional, that means it might either be empty or validated with another validator */
export const optional = (text) => {
   return text == '' || text == null ? false : '*';
};

export const notNull = (value) => {
   return value == undefined ? '*' : false;
};

/**
 * Default text validator
 * Check if text longer than 1 char
 */
export const validateText = (text) => {
   return text.length >= 2 ? false : `tooShort`;
};

/**
 * Check if text is a valid email adress
 */
export const validateEmail = (text) => {
   var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
   return re.test(text) ? false : `invalid`;
};

/**
 * Check if text is a valid mobile number with country code
 */
export const validateMobile = (text) => {
   var re = /^([+])[\w\d]{8,14}$/;
   return re.test(text) ? false : 'invalid';
};

export const validatePasswords = (password, passwordRepeat) => {
   return password == passwordRepeat ? false : `invalid`;
};

export const validateVat = (text) => {
   return text.length >= 8 ? false : `invalid`;
};

export const validateIban = (text) => {
   return text.length >= 15 && text.length <= 34 ? false : `invalid`;
};

const periodsOverlaps = (date1, date2) => {
   const range1 = moment.range(date1.from_date, date1.to_date);
   const range2 = moment.range(date2.from_date, date2.to_date);
   return range1.overlaps(range2);
};

export const getDatesValidationError = (dates) => {
   if (dates !== undefined) {
      for (let i = 0; i < dates.length; i++) {
         for (let j = 0; j < dates.length; j++) {
            if (i !== j && periodsOverlaps(dates[i], dates[j])) {
               return `periodsShouldNotOverlap`;
            }
         }
      }
      for (let i = 0; i < dates.length; i++) {
         if (!dates[i].daily_rate || dates[i].daily_rate < 0)
            return `priceIsRequired`;
      }
   }

   return false;
};

export const greaterThanOrEQ = (limit, value) => {
   if (!value) return '*';
   else if (parseFloat(value) < limit) return 'invalid';
   else return false;
};

/**How to validate a form:
 * in render() call formErrors = validate[whatever]Form(this.state) with the state as argument
 * and then render the eventual error messages in the Input:
 * <input  value=this.state.email... error = formErrors.email>
 */

//Register
export const validateRegisterform = (state) => {
   const errors = {};
   errors.role = required(state.role);
   errors.communication_language = required(state.communication_language);
   errors.lastname = required(state.lastname) || validateText(state.lastname);
   errors.email = required(state.email) || validateEmail(state.email);
   errors.mobilenumber =
      required(state.mobilenumber) || validateMobile(state.mobilenumber);

   errors.referral_account =
      optional(state.referral_account) && validateEmail(state.referral_account);
   errors.AcceptedTCs = required(state.AcceptedTCs);

   errors.password = required(state.password) || validateText(state.password);
   errors.passwordRepeat =
      required(state.passwordRepeat) ||
      validatePasswords(state.password, state.passwordRepeat);

   if (state.account_token) {
      // errors.AcceptedTCs = false;

      if (state.continue_as_guest) {
         errors.AcceptedTCs = false;
         errors.password = false;
         errors.passwordRepeat = false;
      }
      const { boat } = state;
      errors.registration_number = one_required(
         boat.registration_number,
         boat.name,
      );
      errors.name = one_required(boat.registration_number, boat.name);
      errors.length = required(boat.length);
      errors.beam = required(boat.beam);
   }

   return errors;
};

//Contact
export const validateCreateContactForm = (contact) => {
   const errors = {};
   errors.communication_language = required(contact.communication_language);
   errors.lastname =
      required(contact.lastname) || validateText(contact.lastname);
   errors.street = required(contact.street) || validateText(contact.street);
   errors.zip = required(contact.zip) || validateText(contact.zip);
   errors.city = required(contact.city) || validateText(contact.city);
   errors.type = required(contact.type) || validateText(contact.type);

   return errors;
};

//WaitingContact
export const validateCreateWaitingContactForm = (contact) => {
   const errors = {};
   errors.contact_id = required(contact.contact_id);
   errors.entry_date = required(contact.entry_date);

   return errors;
};

//Account (Web App)
export const validateAccountForm = (state) => {
   const errors = {};

   errors.role = required(state.role);
   errors.communication_language = required(state.communication_language);
   errors.lastname = required(state.lastname) || validateText(state.lastname);
   // errors.email = required(state.email) || validateEmail(state.email);
   // errors.mobilenumber = required(state.mobilenumber) || validateMobile(state.mobilenumber);
   errors.vat = optional(state.vat) && validateVat(state.vat);

   if (state.confirm_bookings || state.long_stay_on_request) {
      errors.auto_confirm = notNull(state.auto_confirm);
      errors.auto_confirm_delay = required(state.auto_confirm_delay);
      errors.auto_confirm_delay = greaterThanOrEQ(1, state.auto_confirm_delay);
   }

   return errors;
};

//Bankaccount
export const validateBankAccountForm = (state) => {
   const errors = {};

   errors.currency = required(state.currency);
   errors.owner_name = required(state.owner_name);
   errors.street = required(state.street);
   errors.zip = required(state.zip);
   errors.city = required(state.city);
   errors.country = required(state.country);
   errors.iban = required(state.iban) || validateIban(state.iban);

   return errors;
};

//CreateMooring
export const validateCreateMooringForm = (state) => {
   const errors = {};
   const { mooring } = state;
   errors.app_visible = notNull(mooring.app_visible);
   errors.max_length = required(mooring.max_length);
   errors.max_beam = required(mooring.max_beam);
   errors.max_draught = required(mooring.max_draught);
   errors.harbour_name = required(mooring.harbour_name);
   errors.berth_number = required(mooring.berth_number);
   errors.power = notNull(mooring.power);
   errors.water = notNull(mooring.water);
   errors.buoy = notNull(mooring.buoy);
   // errors.payment_currency = !Boolean(mooring.app_visible) && notNull(mooring.payment_currency);
   // errors.daily_rate_default = !Boolean(mooring.app_visible) && required(mooring.daily_rate_default);

   return errors;
};

//MaintainTimesAndPrices
export const validateMaintainTimesAndPricesForm = (state) => {
   const errors = {};

   const { booking_times } = state;
   errors.main = getDatesValidationError(booking_times);

   return errors;
};

export const validateNotZero = (number) => {
   console.log('NUMVER ', number);
   if (!number) return '*';
   else if (parseInt(number) == 0) return `invalid`;
   else return false;
};

//AddBooking
export const validateAddBookingForm = (state) => {
   const errors = {};

   //No taxes, no erroes
   if (state.mooring.tourist_tax_adult == 0) {
      return errors;
   }

   //No errors for own mooring
   if (state.mooring.own) {
      return errors;
   }

   errors.numberOfAdults = validateNotZero(state.numberOfAdults);
   errors.numberOfChildren = required(state.numberOfChildren);

   return errors;
};

//Boat
export const validateBoatForm = (boat) => {
   const errors = {};
   errors.registration_number = one_required(
      boat.registration_number,
      boat.name,
   );
   errors.name = one_required(boat.registration_number, boat.name);
   errors.length = required(boat.length);
   errors.beam = required(boat.beam);

   return errors;
};

export const validateCreateBoatDocumentForm = (state) => {
   const errors = {};
   errors.document_title = required(state.boat_document.document_title);
   errors.selectedFileName = required(state.selectedFileName);

   return errors;
};

export const validateFastBookingForm = (state) => {
   const errors = {};
   errors.email = required(state.email);
   errors.mobile = required(state.mobile) || validateMobile(state.mobile);

   errors.lastname = required(state.lastname);

   const show_tax_info =
      state.mooring?.tourist_tax_adult != 0 ||
      state.mooring?.tourist_tax_child != 0;

   const show_number_of_people_inputs =
      show_tax_info ||
      state.mooring?.requirements?.includes('number_of_adults') ||
      state.mooring?.requirements?.includes('number_of_children');

   if (show_number_of_people_inputs) {
      errors.number_of_adults = required(state.number_of_adults);
      errors.number_of_children = required(state.number_of_children);
   }

   // Dynamic price for boat sizes
   const price_by_size = state.mooring?.requirements?.includes('boat_length');
   if (price_by_size) {
      errors.boat_length = required(state.boat_length);
      errors.boat_beam = required(state.boat_beam);

      if (state.boat_length < 1 || state.boat_length > 99) {
         errors.boat_length = `invalid`;
      }
      if (state.boat_beam < 1 || state.boat_beam > 99) {
         errors.boat_beam = `invalid`;
      }

      errors.boat_name = one_required(
         state.boat_registration_number,
         state.boat_name,
      );
      errors.boat_registration_number = one_required(
         state.boat_registration_number,
         state.boat_name,
      );
   }

   if (state.loggedIn) {
      const boat = state.boats[state.selectedBoatIndex];

      errors.selectedBoatIndex =
         notEqualToVariable(state.selectedBoatIndex, state.boats.length) &&
         boat?.length &&
         boat?.beam
            ? false
            : '*';
      errors.selectedBoatIndex = notEqualToVariable(
         state.selectedBoatIndex,
         state.boats.length,
      );
   }

   if (state.activationMode) {
      errors.mobile_activation_code = required(state.mobile_activation_code);
   }
   return errors;
};

export const validateResetPasswordForm = (state) => {
   const errors = {};
   errors.password = required(state.password) || validateText(state.password);
   errors.repeatPassword =
      required(state.repeatPassword) ||
      validatePasswords(state.password, state.repeatPassword);
   return errors;
};

export const validateEditAssignedContactModal = (assignedContact) => {
   const errors = {};
   errors.from_date = required(assignedContact?.from_date);
   errors.to_date = required(assignedContact?.to_date);
   return errors;
};

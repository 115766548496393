import React, {
   createRef,
   type CSSProperties,
   useCallback,
   useImperativeHandle,
   useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import colors from '../constants/colors';

type BlockedIpModalRefType = {
   show: () => void;
};

export const blockedIpModalRef = createRef<BlockedIpModalRefType>();

const i18nPrefix = 'BlockedIpModal';

const BlockedIpModal = () => {
   const { t } = useTranslation();

   const [showModal, setShowModal] = useState(false);

   const onClose = useCallback(() => {
      setShowModal(false);
   }, []);

   useImperativeHandle(
      blockedIpModalRef,
      () => {
         return {
            show: () => {
               setShowModal(true);
            },
         };
      },
      [],
   );

   return (
      <Modal centered show={showModal}>
         <Modal.Header style={styles.header} closeButton>
            <Modal.Title>{t(`${i18nPrefix}.title`)}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <span>{t(`${i18nPrefix}.description`)}</span>
            <div style={styles.contactInfoContainer}>
               <span style={styles.contactInfoText}>
                  {t(`${i18nPrefix}.contactInfo`)}
               </span>
               <div style={styles.infoRow}>
                  <span>{t('global.email')}:</span>
                  <span style={styles.contactValue}>
                     {t('global.boatparkContactEmail')}
                  </span>
               </div>
               <div style={styles.infoRow}>
                  <span>{t('global.mobile')}:</span>
                  <span style={styles.contactValue}>
                     {t('global.boatparkContactMobile')}
                  </span>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Button
               variant='primary'
               onClick={() => {
                  onClose();
               }}>
               {t('global.ok')}
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

const styles: { [key: string]: CSSProperties } = {
   header: {
      backgroundColor: colors.MAIN_RED,
   },
   contactInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 20,
      rowGap: 5,
   },
   contactInfoText: {
      fontSize: 17,
      fontWeight: 500,
   },
   infoRow: {
      display: 'flex',
      columnGap: 5,
   },
   contactValue: {
      textDecorationLine: 'underline',
      fontWeight: 500,
   },
};

export default BlockedIpModal;

import moment from 'moment';
import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { getBookingConfirmationRequest } from '../actions/booking_confirmation_requests';
import FormInput from '../components/FormInput';
import { DATE_FORMAT } from '../constants/format';

const base_api_url = process.env.REACT_APP_API_HOST;

class ConfirmBooking extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         token: '',
         booking: {},
      };
   }

   componentDidMount() {
      if (this.props.match.params.token) {
         const token = this.props.match.params.token;
         this.setState({ token: token });

         getBookingConfirmationRequest(
            token,
            (booking) => {
               this.setState({ booking: booking });
            },
            (err) => {
               this.setState({ booking: null });
            },
         );
      }
   }

   handleAccept() {
      const url = `${base_api_url}/bookings/confirm_booking?token=${this.state.token}&confirm=1&language=${this.props.i18n.language}`;
      window.location.href = url;
   }

   handleDecline() {
      const url = `${base_api_url}bookings/confirm_booking?token=${this.state.token}&confirm=0&language=${this.props.i18n.language}`;
      window.location.href = url;
   }

   render() {
      const { t } = this.props;

      if (!this.state.booking) {
         return (
            <Container
               style={{ maxWidth: 500, marginTop: 100, borderRadius: 5 }}>
               <h4 className='text-center mb-4'>{t(`ConfirmBooking.title`)}</h4>
               <FormInput
                  id='input_reservation_name'
                  readOnly
                  type='text'
                  label={t('ConfirmBooking.reservationName')}
                  value={`${this.state.booking.reservation_name}`}
               />
               <FormInput
                  id='input_from_date'
                  readOnly
                  type='text'
                  label={t('ConfirmBooking.fromDate')}
                  value={moment(this.state.booking.from_date).format(
                     DATE_FORMAT,
                  )}
               />
               <FormInput
                  id='input_to_date'
                  readOnly
                  type='text'
                  label={t('ConfirmBooking.toDate')}
                  value={moment(this.state.booking.to_date).format(DATE_FORMAT)}
               />
               <FormInput
                  id='input_marina_fee_included'
                  readOnly
                  type='text'
                  label={t('ConfirmBooking.marinaFeeIncluded')}
                  value={
                     this.state.booking.marina_fee_included
                        ? t('ConfirmBooking.marinaFeeIncludedYes')
                        : t('ConfirmBooking.marinaFeeIncludedNo')
                  }
               />
               <FormInput
                  id='input_booking_price'
                  readOnly
                  type='number'
                  label={t('ConfirmBooking.bookingPrice')}
                  value={`${this.state.booking.booking_price}`}
                  unit={`${this.state.booking.currency}`}
               />
               <FormInput
                  id='input_tax_price'
                  readOnly
                  type='number'
                  label={t('ConfirmBooking.taxes')}
                  value={`${this.state.booking.tax_price}`}
                  unit={`${this.state.booking.currency}`}
               />
               <FormInput
                  id='input_total_price'
                  readOnly
                  type='number'
                  label={t('ConfirmBooking.totalPrice')}
                  value={`${this.state.booking.total_price}`}
                  unit={`${this.state.booking.currency}`}
               />
               <div className='d-flex justify-content-between mt-4'>
                  <Button
                     id='accept'
                     variant='success'
                     className='w-50 mx-2'
                     onClick={() => this.handleAccept()}>
                     {t(`ConfirmBooking.accept`)}
                  </Button>
                  <Button
                     id='decline'
                     variant='danger'
                     className='w-50 mx-2'
                     onClick={() => this.handleDecline()}>
                     {t(`ConfirmBooking.decline`)}
                  </Button>
               </div>
            </Container>
         );
      }

      return (
         <Container style={{ maxWidth: 500, marginTop: 100, borderRadius: 5 }}>
            <h4 className='text-center mb-4'>{t(`ConfirmBooking.title`)}</h4>
            <FormInput
               id='input_reservation_name'
               readOnly
               type='text'
               label={t('ConfirmBooking.reservationName')}
               value={`${this.state.booking.reservation_name}`}
            />
            <FormInput
               id='input_from_date'
               readOnly
               type='text'
               label={t('ConfirmBooking.fromDate')}
               value={moment(this.state.booking.from_date).format(DATE_FORMAT)}
            />
            <FormInput
               id='input_to_date'
               readOnly
               type='text'
               label={t('ConfirmBooking.toDate')}
               value={moment(this.state.booking.to_date).format(DATE_FORMAT)}
            />
            <FormInput
               id='input_marina_fee_included'
               readOnly
               type='text'
               label={t('ConfirmBooking.marinaFeeIncluded')}
               value={
                  this.state.booking.marina_fee_included
                     ? t('ConfirmBooking.marinaFeeIncludedYes')
                     : t('ConfirmBooking.marinaFeeIncludedNo')
               }
            />
            <FormInput
               id='input_booking_price'
               readOnly
               type='number'
               label={t('ConfirmBooking.bookingPrice')}
               value={`${this.state.booking.booking_price}`}
               unit={`${this.state.booking.currency}`}
            />
            <FormInput
               id='input_tax_price'
               readOnly
               type='number'
               label={t('ConfirmBooking.taxes')}
               value={`${this.state.booking.tax_price}`}
               unit={`${this.state.booking.currency}`}
            />
            <FormInput
               id='input_total_price'
               readOnly
               type='number'
               label={t('ConfirmBooking.totalPrice')}
               value={`${this.state.booking.total_price}`}
               unit={`${this.state.booking.currency}`}
            />
            <div className='d-flex justify-content-between mt-4'>
               <Button
                  id='accept'
                  variant='success'
                  className='w-50 mx-2'
                  onClick={() => this.handleAccept()}>
                  {t(`ConfirmBooking.accept`)}
               </Button>
               <Button
                  id='decline'
                  variant='danger'
                  className='w-50 mx-2'
                  onClick={() => this.handleDecline()}>
                  {t(`ConfirmBooking.decline`)}
               </Button>
            </div>
         </Container>
      );
   }
}

export default withTranslation()(ConfirmBooking);

import i18n from 'i18next'; // Import i18n instance
import React from 'react';
import {
   Button,
   Col,
   Collapse,
   Container,
   Form,
   FormSelect,
   Row,
} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import {
   getMyAccountData,
   getMyBankAccountData,
   updateAccountData,
   updateBankAccountData,
} from '../actions/account';
import { logoutRequest } from '../actions/auth';
import { getMyContactTypes, updateContactTypes } from '../actions/contacts';
import {
   getMyMooringTypes,
   getMyMooringZones,
   updateMooringTypes,
   updateMooringZones,
} from '../actions/moorings';
import DropDownIcon from '../assets/icons/dropdown.png';
import AddNewButton from '../components/AddNewButton';
import CountrySelector from '../components/CountrySelector';
import FormInput from '../components/FormInput';
import FormSegmentedControl from '../components/FormSegmentedControl';
import StatusAlerts from '../components/StatusAlerts';
import COLORS from '../constants/colors';
import {
   COMMUNICATION_LANGUAGES,
   CURRENCIES,
   ROLES,
} from '../constants/constants';
import globalStyles from '../stylesheets/globalStyles';
import { validateAccountForm } from '../utils/validators';

class Account extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         success: false,
         error: false,
         role: '',
         communication_language: '',
         firstname: '',
         lastname: '',
         email: '',
         mobilenumber: '',
         password: '',
         showPassword: false,
         passwordRepeat: '',
         showPasswordRepeat: false,
         referral_account: '',
         AcceptedTCs: false,
         confirm_bookings: false,
         confirmation_emails_own: false,
         bankAccountData: [],
         mooring_types: [],
         mooring_zones: [],
         contact_types: [],
         showBankAccountData: false,
         showMooringZones: false,
         showMooringTypes: false,
         showContactTypes: false,
      };
   }

   componentDidMount() {
      getMyAccountData(this.getMyAccountDataSuccess, this.getMyAccountDataFail);

      getMyBankAccountData(
         (bankAccountData) => {
            this.setState({ bankAccountData: bankAccountData });
         },
         () => {},
      );

      getMyMooringTypes(
         (mooring_types) => {
            this.setState({ mooring_types: mooring_types });
         },
         (error) => {
            console.log('Error', error);
         },
      );

      getMyMooringZones(
         (mooring_zones) => {
            this.setState({ mooring_zones: mooring_zones });
         },
         (error) => {
            console.log('Error', error);
         },
      );

      getMyContactTypes(
         (contact_types) => {
            this.setState({ contact_types: contact_types });
         },
         (error) => {
            console.log('Error', error);
         },
      );
   }

   getMyAccountDataSuccess = (accountData) => {
      console.log(accountData);
      accountData.password = '';
      this.setState(accountData);
   };

   getMyAccountDataFail = (error) => {};

   handleUpdateClick() {
      if (
         !Object.values(validateAccountForm(this.state)).every(
            (error) => !error,
         )
      ) {
         // There are some validation errors, do not proceed with updating the account data
         this.setState({
            error: true,
            errorMessage: this.props.t('errors.someValuesAreNotCorrect'),
         });
         return;
      }

      const accountData = this.state;
      this.setState({ loading: true, success: false, error: false }, () => {
         updateAccountData(
            accountData,
            this.updateAccountDataSuccess,
            this.updateAccountDataFail,
         );
         updateMooringTypes(
            this.state.mooring_types,
            () => {},
            () => {},
         );
         updateMooringZones(
            this.state.mooring_zones,
            () => {},
            () => {},
         );
         updateContactTypes(
            this.state.contact_types,
            () => {},
            () => {},
         );
         updateBankAccountData(
            this.state.bankAccountData,
            () => {},
            () => {},
         );
      });
   }

   updateAccountDataSuccess = (response) => {
      this.setState({ loading: false, success: true, error: false });
   };

   updateAccountDataFail = (error) => {
      this.setState({ loading: false, success: false, error: true });
   };

   render() {
      const { t } = this.props;
      const NoOrYes = t('global.NoOrYes');
      const { role } = this.state;

      const dropDownArrowStyle = {
         transition: 'transform 250ms ease', // smooth transition
         float: 'right',
         height: 15,
         marginTop: 8,
         marginRight: 10,
      };

      const formErrors = validateAccountForm(this.state);

      return (
         <Container sm={12}>
            <Row>
               <Col md={8}>
                  <h1>{t('Account.title')}</h1>
               </Col>
               <Col md={4}>
                  <Button
                     id='button_save'
                     style={{
                        ...globalStyles.saveButton,
                        float: 'right',
                        marginLeft: 10,
                     }}
                     disabled={this.state.loading}
                     onClick={() => this.handleUpdateClick()}>
                     {t('global.save')}
                  </Button>
                  <Button
                     id='button_logout'
                     style={{ ...globalStyles.deleteButton, float: 'right' }}
                     disabled={this.state.loading}
                     onClick={() =>
                        logoutRequest(
                           () => {
                              window.location.replace('/login');
                           },
                           () => {},
                        )
                     }>
                     {t('Navbar.logout')}
                  </Button>
               </Col>
            </Row>
            <Row>
               <Col md={12}>
                  <StatusAlerts
                     state={this.state}
                     errorMessage={this.state.errorMessage}
                  />
               </Col>
            </Row>
            <Row>
               <Col sm={6} style={{ backgroundColor: 'white' }}>
                  <h3>{t('Account.title')}</h3>

                  {/* <FormSegmentedControl
              label={t('Account.role')}
              segments={ROLES}
              onChange={(index) => { this.setState({ role: ROLES[index] }); }}
              value={this.state.role}
              error={formErrors?.role}
            /> */}

                  <FormInput
                     as='select'
                     id='input_role'
                     label={t('Account.role')}
                     value={this.state.role}
                     error={formErrors?.role}
                     onChange={(event) => {
                        this.setState({ role: event.target.value });
                     }}
                     disabled>
                     <option value='private'>Private</option>
                     <option value='marina'>Marina</option>
                     <option value='management'>Management</option>
                  </FormInput>

                  <FormInput
                     type='text'
                     label={t('Account.salutation')}
                     value={this.state.salutation}
                     error={formErrors?.salutation}
                     onChange={(event) =>
                        this.setState({ salutation: event.target.value })
                     }
                  />

                  <FormInput
                     type='text'
                     label={t('Account.firstName')}
                     value={this.state.firstname}
                     error={formErrors?.firstname}
                     onChange={(event) =>
                        this.setState({ firstname: event.target.value })
                     }
                  />
                  <FormInput
                     type='text'
                     id={'lastname'}
                     label={t('Account.lastName')}
                     value={this.state.lastname}
                     error={formErrors?.lastname}
                     onChange={(event) =>
                        this.setState({ lastname: event.target.value })
                     }
                  />

                  <FormSelect
                     label={t('Account.communicationLanguage')}
                     value={this.state.communication_language}
                     error={formErrors?.communication_language}
                     onChange={(event) =>
                        this.setState(
                           {
                              communication_language: event.target.value,
                           },
                           () => {
                              i18n.changeLanguage(
                                 String(event.target.value).toLowerCase(),
                              );
                           },
                        )
                     }>
                     {COMMUNICATION_LANGUAGES.map((language, index) => {
                        return (
                           <option key={index} value={language}>
                              {language}
                           </option>
                        );
                     })}
                  </FormSelect>
                  <FormInput
                     disabled
                     type='text'
                     label={t('Account.email')}
                     value={this.state.email}
                     error={formErrors?.email}
                     onChange={(event) =>
                        this.setState({ email: event.target.value })
                     }
                  />
                  <FormInput
                     disabled
                     type='tel'
                     label={t('Account.mobile')}
                     value={this.state.mobilenumber}
                     error={formErrors?.mobilenumber}
                     onChange={(event) =>
                        this.setState({ mobilenumber: event.target.value })
                     }
                  />
                  <FormInput
                     type='text'
                     label={t('Account.street')}
                     value={this.state.street}
                     error={formErrors?.street}
                     onChange={(event) =>
                        this.setState({ street: event.target.value })
                     }
                  />
                  <FormInput
                     type='text'
                     label={t('Account.zip')}
                     value={this.state.zip}
                     error={formErrors?.zip}
                     onChange={(event) =>
                        this.setState({ zip: event.target.value })
                     }
                  />
                  <FormInput
                     type='text'
                     label={t('Account.city')}
                     value={this.state.city}
                     error={formErrors?.city}
                     onChange={(event) =>
                        this.setState({ city: event.target.value })
                     }
                  />
                  <CountrySelector
                     label={t('Account.country')}
                     value={this.state.country}
                     error={formErrors?.country}
                     onChange={(value) => this.setState({ country: value })}
                  />
                  <FormInput
                     type='text'
                     label={t('Account.vat')}
                     value={this.state.vat}
                     error={formErrors?.vat}
                     onChange={(event) =>
                        this.setState({ vat: event.target.value })
                     }
                  />

                  <Row
                     onClick={() =>
                        this.setState({
                           showBankAccountData: !this.state.showBankAccountData,
                        })
                     }>
                     <Col md={10}>
                        <h3>{t('Account.bankAccountData')}</h3>
                     </Col>
                     <Col>
                        <img
                           style={{
                              ...dropDownArrowStyle,
                              transform: this.state.showBankAccountData
                                 ? 'rotate(180deg)'
                                 : '',
                           }}
                           src={DropDownIcon}
                           alt='DropDownIcon'
                           className='spin'
                        />
                     </Col>
                  </Row>

                  <Collapse in={this.state.showBankAccountData}>
                     <div id='showBankAccountData'>
                        <FormSegmentedControl
                           label={t('Account.currency')}
                           segments={CURRENCIES}
                           onChange={(index) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    currency: CURRENCIES[index],
                                 },
                              })
                           }
                           selected={CURRENCIES.indexOf(
                              this.state.bankAccountData.currency,
                           )}
                        />
                        <FormInput
                           type='text'
                           label={t('Account.iban')}
                           value={this.state.bankAccountData.iban}
                           error={formErrors?.iban}
                           onChange={(event) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    iban: event.target.value,
                                 },
                              })
                           }
                        />
                        <FormInput
                           type='text'
                           label={t('Account.owner_name')}
                           value={this.state.bankAccountData.owner_name}
                           error={formErrors?.owner_name}
                           onChange={(event) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    owner_name: event.target.value,
                                 },
                              })
                           }
                        />
                        <FormInput
                           type='text'
                           label={t('Account.street')}
                           value={this.state.bankAccountData.street}
                           error={formErrors?.street}
                           onChange={(event) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    street: event.target.value,
                                 },
                              })
                           }
                        />
                        <FormInput
                           type='text'
                           label={t('Account.zip')}
                           value={this.state.bankAccountData.zip}
                           error={formErrors?.zip}
                           onChange={(event) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    zip: event.target.value,
                                 },
                              })
                           }
                        />
                        <FormInput
                           type='text'
                           label={t('Account.city')}
                           value={this.state.bankAccountData.city}
                           error={formErrors?.city}
                           onChange={(event) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    city: event.target.value,
                                 },
                              })
                           }
                        />
                        <CountrySelector
                           label={t('Account.country')}
                           value={this.state.bankAccountData.country}
                           error={formErrors?.country}
                           onChange={(value) =>
                              this.setState({
                                 bankAccountData: {
                                    ...this.state.bankAccountData,
                                    country: value,
                                 },
                              })
                           }
                        />
                     </div>
                  </Collapse>
                  <div
                     style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: COLORS.GRAY_OUTLINE,
                     }}></div>

                  <h3>{t('Account.changePassword')}</h3>
                  <FormInput
                     type='password'
                     label={t('Account.password')}
                     value={this.state.password}
                     error={formErrors?.password}
                     onChange={(event) =>
                        this.setState({ password: event.target.value })
                     }
                     autocomplete='new-password'
                  />
                  <FormInput
                     type='password'
                     label={t('Account.passwordRepeat')}
                     value={this.state.passwordRepeat}
                     error={formErrors?.passwordRepeat}
                     onChange={(event) =>
                        this.setState({ passwordRepeat: event.target.value })
                     }
                     autocomplete='new-password'
                  />
               </Col>

               <Col sm={6} style={{ backgroundColor: 'white' }}>
                  {/* <h3>{t('Account.settings')}</h3> */}

                  <h3>{t('Account.booking_settings')}</h3>

                  <FormInput
                     type='number'
                     label={t('Account.max_nights_per_year')}
                     value={this.state.max_nights_per_year}
                     error={formErrors?.max_nights_per_year}
                     onChange={(event) =>
                        this.setState({
                           max_nights_per_year: event.target.value,
                        })
                     }
                  />
                  <FormInput
                     type='number'
                     label={t('Account.max_nights_break')}
                     value={this.state.max_nights_break}
                     error={formErrors?.max_nights_break}
                     onChange={(event) =>
                        this.setState({
                           max_nights_break: event.target.value,
                        })
                     }
                  />
                  <FormSegmentedControl
                     label={t('Account.allow_booking_unreleased')}
                     selected={Number(this.state.allow_booking_unreleased)}
                     variant='base'
                     segments={NoOrYes}
                     onChangeSegment={(index) =>
                        this.setState({
                           allow_booking_unreleased: Boolean(index),
                        })
                     }
                  />
                  <FormSegmentedControl
                     label={t('Account.manual_booking_confirmation')}
                     selected={this.state.confirm_bookings}
                     variant='base'
                     segments={NoOrYes}
                     onChangeSegment={(index) =>
                        this.setState({
                           confirm_bookings: index,
                        })
                     }
                  />
                  <FormSegmentedControl
                     label={t('Account.bookingRequestsForLongTermBookings')}
                     selected={this.state.long_stay_on_request}
                     variant='base'
                     segments={NoOrYes}
                     onChangeSegment={(index) =>
                        this.setState({
                           long_stay_on_request: index,
                        })
                     }
                  />

                  {(Boolean(this.state.confirm_bookings) ||
                     Boolean(this.state.long_stay_on_request)) && (
                     <FormSegmentedControl
                        label={t('Account.automatic_response')}
                        selected={this.state.auto_confirm}
                        variant='base'
                        segments={[t('Account.decline'), t('Account.accept')]}
                        onChangeSegment={(index) =>
                           this.setState({
                              auto_confirm: index,
                           })
                        }
                     />
                  )}

                  {(Boolean(this.state.confirm_bookings) ||
                     Boolean(this.state.long_stay_on_request)) && (
                     <FormInput
                        type='number'
                        label={t('Account.automatic_response_after')}
                        unit={'h'}
                        value={this.state.auto_confirm_delay}
                        error={formErrors?.auto_confirm_delay}
                        onChange={(event) =>
                           this.setState({
                              auto_confirm_delay: event.target.value,
                           })
                        }
                     />
                  )}

                  <h3>{t('Account.email_settings')}</h3>

                  <FormSegmentedControl
                     label={t('Account.confirmation_emails')}
                     selected={this.state.confirmation_emails}
                     variant='base'
                     segments={NoOrYes}
                     onChangeSegment={(index) =>
                        this.setState({
                           confirmation_emails: index,
                        })
                     }
                  />
                  {Boolean(this.state.confirmation_emails) && (
                     <FormSegmentedControl
                        label={t('Account.confirmation_emails_own')}
                        selected={this.state.confirmation_emails_own}
                        segments={NoOrYes}
                        onChangeSegment={(index) =>
                           this.setState({
                              confirmation_emails_own: index,
                           })
                        }
                     />
                  )}
                  <FormInput
                     type='text'
                     label={t('Account.email_cc')}
                     value={this.state.email_cc}
                     error={formErrors?.email_cc}
                     onChange={(event) =>
                        this.setState({ email_cc: event.target.value })
                     }
                  />

                  <FormInput
                     type='text'
                     label={t('Account.email_admin_cc')}
                     value={this.state.email_admin_cc}
                     error={formErrors?.email_admin_cc}
                     onChange={(event) =>
                        this.setState({ email_admin_cc: event.target.value })
                     }
                  />

                  <Row>
                     <Col>
                        <FormInput
                           type='text'
                           label={t('Account.decimal_separator')}
                           value={this.state.decimal_separator}
                           error={formErrors?.decimal_separator}
                           onChange={(event) =>
                              this.setState({
                                 decimal_separator: event.target.value,
                              })
                           }
                        />
                     </Col>
                     <Col>
                        <FormInput
                           type='text'
                           label={t('Account.csv_separator')}
                           value={this.state.csv_separator}
                           error={formErrors?.csv_separator}
                           onChange={(event) =>
                              this.setState({
                                 csv_separator: event.target.value,
                              })
                           }
                        />
                     </Col>
                  </Row>

                  <FormSegmentedControl
                     label={t('Account.detailed_billing_email')}
                     selected={Number(this.state.show_billing_email_details)}
                     variant='base'
                     segments={NoOrYes}
                     onChangeSegment={(index) =>
                        this.setState({
                           show_billing_email_details: index,
                        })
                     }
                  />

                  {role == 'management' && (
                     <div>
                        <h2>{t('Account.types')}</h2>

                        <Row
                           onClick={() =>
                              this.setState({
                                 showMooringTypes: !this.state.showMooringTypes,
                              })
                           }>
                           <Col md={10}>
                              <h3>{t('Account.mooringTypes')}</h3>
                           </Col>
                           <Col>
                              <img
                                 style={{
                                    ...dropDownArrowStyle,
                                    transform: this.state.showMooringTypes
                                       ? 'rotate(180deg)'
                                       : '',
                                 }}
                                 src={DropDownIcon}
                                 alt='DropDownIcon'
                                 className='spin'
                              />
                           </Col>
                        </Row>

                        <Collapse in={this.state.showMooringTypes}>
                           <div id='showMooringTypes'>
                              {this.state.mooring_types.map(
                                 (mooring_type, index) => (
                                    <div key={index}>
                                       <Row md={12}>
                                          <Col md={10}>
                                             <Form.Control
                                                type='text'
                                                label={'platztyp'}
                                                value={
                                                   this.state.mooring_types[
                                                      index
                                                   ].mooring_type
                                                }
                                                // error={formErrors?.mooring_types[index]?.mooring_type}
                                                onChange={(event) => {
                                                   const new_type =
                                                      event.target.value;
                                                   const mooring_types = [
                                                      ...this.state
                                                         .mooring_types,
                                                   ];
                                                   const mooring_type =
                                                      mooring_types[index];
                                                   mooring_type.mooring_type =
                                                      new_type;
                                                   mooring_types[index] =
                                                      mooring_type;
                                                   this.setState({
                                                      mooring_types:
                                                         mooring_types,
                                                   });
                                                }}
                                             />
                                          </Col>

                                          <Col md={2}>
                                             <Button
                                                style={
                                                   globalStyles.deleteButton
                                                }
                                                onClick={() => {
                                                   const mooring_types = [
                                                      ...this.state
                                                         .mooring_types,
                                                   ];
                                                   mooring_types.splice(
                                                      index,
                                                      1,
                                                   );
                                                   this.setState({
                                                      mooring_types,
                                                   });
                                                }}>
                                                {t('global.delete')}
                                             </Button>
                                          </Col>
                                       </Row>
                                    </div>
                                 ),
                              )}

                              <AddNewButton
                                 center
                                 label={t('Account.addMooringType')}
                                 onClick={() => {
                                    const mooring_type = {
                                       mooring_type: '',
                                    };
                                    this.setState({
                                       mooring_types: [
                                          ...this.state.mooring_types,
                                          mooring_type,
                                       ],
                                    });
                                 }}
                              />
                           </div>
                        </Collapse>
                        <div
                           style={{
                              width: '100%',
                              height: 1,
                              backgroundColor: COLORS.GRAY_OUTLINE,
                           }}></div>

                        <Row
                           onClick={() =>
                              this.setState({
                                 showMooringZones: !this.state.showMooringZones,
                              })
                           }>
                           <Col md={10}>
                              <h3>{t('Account.mooringZones')}</h3>
                           </Col>
                           <Col>
                              <img
                                 style={{
                                    ...dropDownArrowStyle,
                                    transform: this.state.showMooringZones
                                       ? 'rotate(180deg)'
                                       : '',
                                 }}
                                 src={DropDownIcon}
                                 alt='DropDownIcon'
                                 className='spin'
                              />
                           </Col>
                        </Row>
                        <Collapse in={this.state.showMooringZones}>
                           <div id={'showMooringZones'}>
                              {this.state.mooring_zones.map(
                                 (mooring_zone, index) => (
                                    <div key={index}>
                                       <Row md={12}>
                                          <Col md={10}>
                                             <Form.Control
                                                type='text'
                                                label={'platztyp'}
                                                value={mooring_zone.zone}
                                                onChange={(event) => {
                                                   const new_zone =
                                                      event.target.value;
                                                   const mooring_zones = [
                                                      ...this.state
                                                         .mooring_zones,
                                                   ];
                                                   const mooring_zone =
                                                      mooring_zones[index];
                                                   mooring_zone.zone = new_zone;
                                                   mooring_zones[index] =
                                                      mooring_zone;
                                                   this.setState({
                                                      mooring_zones:
                                                         mooring_zones,
                                                   });
                                                }}
                                             />
                                          </Col>

                                          <Col md={2}>
                                             <Button
                                                style={
                                                   globalStyles.deleteButton
                                                }
                                                onClick={() => {
                                                   const mooring_zones = [
                                                      ...this.state
                                                         .mooring_zones,
                                                   ];
                                                   mooring_zones.splice(
                                                      index,
                                                      1,
                                                   );
                                                   this.setState({
                                                      mooring_zones,
                                                   });
                                                }}>
                                                {t('global.delete')}
                                             </Button>
                                          </Col>
                                       </Row>
                                    </div>
                                 ),
                              )}

                              <AddNewButton
                                 center
                                 label={t('Account.addMooringZone')}
                                 onClick={() => {
                                    const mooring_zone = { type: '' };
                                    this.setState({
                                       mooring_zones: [
                                          ...this.state.mooring_zones,
                                          mooring_zone,
                                       ],
                                    });
                                 }}
                              />
                           </div>
                        </Collapse>
                        <div
                           style={{
                              width: '100%',
                              height: 1,
                              backgroundColor: COLORS.GRAY_OUTLINE,
                           }}></div>

                        <Row
                           onClick={() =>
                              this.setState({
                                 showContactTypes: !this.state.showContactTypes,
                              })
                           }>
                           <Col md={10}>
                              <h3>{t('Account.contactTypes')}</h3>
                           </Col>
                           <Col>
                              <img
                                 style={{
                                    ...dropDownArrowStyle,
                                    transform: this.state.showContactTypes
                                       ? 'rotate(180deg)'
                                       : '',
                                 }}
                                 src={DropDownIcon}
                                 alt='DropDownIcon'
                                 className='spin'
                              />
                           </Col>
                        </Row>
                        <Collapse in={this.state.showContactTypes}>
                           <div id={'showContactTypes'}>
                              {this.state.contact_types.map(
                                 (contact_type, index) => (
                                    <div key={index}>
                                       <Row md={12}>
                                          <Col md={10}>
                                             <Form.Control
                                                type='text'
                                                label={'platztyp'}
                                                value={
                                                   this.state.contact_types[
                                                      index
                                                   ].type
                                                }
                                                // error={formErrors?.contact_types[index]?.type}
                                                onChange={(event) => {
                                                   const new_type =
                                                      event.target.value;
                                                   const contact_types = [
                                                      ...this.state
                                                         .contact_types,
                                                   ];
                                                   const contact_type =
                                                      contact_types[index];
                                                   contact_type.type = new_type;
                                                   contact_types[index] =
                                                      contact_type;
                                                   this.setState({
                                                      contact_types:
                                                         contact_types,
                                                   });
                                                }}
                                             />
                                          </Col>

                                          <Col md={2}>
                                             <Button
                                                style={
                                                   globalStyles.deleteButton
                                                }
                                                onClick={() => {
                                                   const contact_types = [
                                                      ...this.state
                                                         .contact_types,
                                                   ];
                                                   contact_types.splice(
                                                      index,
                                                      1,
                                                   );
                                                   this.setState({
                                                      contact_types,
                                                   });
                                                }}>
                                                {t('global.delete')}
                                             </Button>
                                          </Col>
                                       </Row>
                                    </div>
                                 ),
                              )}

                              <AddNewButton
                                 center
                                 label={t('Account.addContactType')}
                                 onClick={() => {
                                    const contact_type = { type: '' };
                                    this.setState({
                                       contact_types: [
                                          ...this.state.contact_types,
                                          contact_type,
                                       ],
                                    });
                                 }}
                              />
                           </div>
                        </Collapse>
                        <div
                           style={{
                              width: '100%',
                              height: 1,
                              backgroundColor: COLORS.GRAY_OUTLINE,
                           }}></div>

                        <FormInput
                           as='select'
                           label={t('Account.default_currency')}
                           value={this.state.default_currency}
                           error={formErrors?.default_currency}
                           onChange={(event) =>
                              this.setState({
                                 default_currency: event.target.value,
                              })
                           }>
                           {CURRENCIES.map((currency, index) => {
                              return (
                                 <option key={index} value={currency}>
                                    {currency}
                                 </option>
                              );
                           })}
                        </FormInput>

                        <FormInput
                           type='number'
                           label={t('Account.default_periodic_fee')}
                           value={this.state.default_periodic_fee}
                           error={formErrors?.default_periodic_fee}
                           onChange={(event) =>
                              this.setState({
                                 default_periodic_fee: event.target.value,
                              })
                           }
                        />

                        <FormInput
                           type='number'
                           label={t('Account.default_registration_fee')}
                           value={this.state.default_registration_fee}
                           error={formErrors?.default_registration_fee}
                           onChange={(event) =>
                              this.setState({
                                 default_registration_fee: event.target.value,
                              })
                           }
                        />

                        <FormInput
                           type='number'
                           unit={t('global.year')}
                           label={t('Account.default_payment_period')}
                           value={this.state.default_payment_period}
                           error={formErrors?.default_payment_period}
                           onChange={(event) =>
                              this.setState({
                                 default_payment_period: event.target.value,
                              })
                           }
                        />
                     </div>
                  )}
               </Col>
            </Row>
         </Container>
      );
   }
}

export default withTranslation()(Account);
